<template>
  <el-button
  size="small"
    type="primary"
    @click="ProvinceCityEditWindow({ TypeFormet: '', Metre: 0 })"
  >
    新增
  </el-button>
  <el-table
  
    :data="ProvinceCityList"
    style="width: 100%; margin-bottom: 20px"
    row-key="Id"
    border
    default-expand-all
  >
    <el-table-column prop="Name" label="名称" width="180" />
    <el-table-column prop="Code" label="邮编" width="180" />
    <el-table-column prop="TypeFormet" label="类型" width="180" />
    <el-table-column>
      <template #default="scope">
        <el-button type="warning" size="small" @click="AddProvinceCityItem(scope.row)"
          >增加</el-button
        >
        <el-button  size="small"  type="danger" @click="ProvinceCityEditWindow(scope.row)"
          >编辑</el-button
        >
        <el-button  size="small"  @click="DeleteProvinceCity(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>

  <!-- 修改新增分类 -->
  <el-dialog v-model="ProvinceCityVisible" title="新增" width="800px">
    <el-form ref="form" label-position="right" label-width="100px">
      <el-form-item label="名称">
        <el-input type="text" v-model="ProvinceCityModel.Name" />
      </el-form-item>
      <el-form-item label="编码">
        <el-input type="text" v-model="ProvinceCityModel.Code" />
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="ProvinceCityModel.Type">
          <el-option
            v-for="item in ProvinceCityType"
            :key="item.Key"
            :label="item.Value"
            :value="item.Key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="距离(M)">
        <el-input-number type="text" v-model="ProvinceCityModel.Metre" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="ProvinceCityVisible = false">关闭</el-button>
        <el-button type="primary" @click="EditProvinceCityCategory()"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js"

import { ElMessageBox, ElMessage } from "element-plus" 
import ProductItem from "../../../components/ProductItem.vue" 

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      TypeFormet: "",
      ProvinceCityModel: {
        TypeFormet: ""
      }, // 当前操作数据
      ProvinceCityList: [],
      ProvinceCityType: [],
      CityType: "",
      ProvinceCityVisible: false
    }
  },
  name: "App",
  mounted () {
    this.GetProvinceCityCategory()
    this.GetProvinceCityType()
  },
  components: { 
    ProductItem, 
  },
  methods: {
    AddProvinceCityItem (item) {
      this.ProvinceCityVisible = true
      this.ProvinceCityModel = {}
      this.ProvinceCityModel.FId = item.Id
    },
    // 编辑
    EditProvinceCityCategory () {
      axios.apiMethod(
        "/api/SystemConfiguration/EditProvinceCityCategory",
        "post",
        this.ProvinceCityModel,
        (result) => {
          if (result.Code == 200) {
            ElMessage({
              message: "操作成功",
              type: "success"
            })
            this.GetProvinceCityCategory()
            this.ProvinceCityVisible = false
          } else {
            ElMessage({
              message: "操作失败"
            })
          }
        }
      )
    },
    // 打开编辑页面
    ProvinceCityEditWindow (item) {
      this.ProvinceCityVisible = true
      this.ProvinceCityModel = item
    },
    ProvinceCityEdit () {},
    // 查询
    GetProvinceCityCategory () {
      const param = {}
      axios.apiMethod(
        "/api/SystemConfiguration/GetProvinceCityCategory",
        "get",
        param,
        (result) => {
          if (result.Code == 200) {
            this.ProvinceCityList = result.Data
          }
        }
      )
    },
    GetProvinceCityType () {
      const param = {}
      axios.apiMethod(
        "/sys/SystemData/GetProvinceCityTypes",
        "get",
        param,
        (result) => {
          if (result.Code == 200) {
            this.ProvinceCityType = result.Data
          }
        }
      )
    },
    // 删除
    DeleteProvinceCity (item) {
      ElMessageBox.confirm("您确定要删除吗", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          axios.apiMethod(
            "/api/SystemConfiguration/DeleteProvinceCity",
            "post",
            item,
            (result) => {
              if (result.Code == 200) {
                ElMessage({
                  message: result.Data.Msg,
                  type: "success"
                })
                this.GetProvinceCityCategory()
              } else {
                ElMessage({
                  message: result.Data.Msg
                })
              }
            }
          )
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
</style>
